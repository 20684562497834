import React from "react"
import { graphql } from "gatsby"
import CustomLayout from '../layout/default'
import { Container, Row, Col } from '../../../shared-ui/components/ui/wrapper'
import SectionTitle from '../../../shared-ui/components/ui/section-title'
import ServiceBox from '../../../shared-ui/components/box-large-image/layout-two'
import Blog from '../../../shared-ui/components/blog/layout-one'
import Heading from '../../../shared-ui/components/ui/heading'
import Text from '../../../shared-ui/components/ui/text'
import Button from '../../../shared-ui/components/ui/button'
import HeroSection from '../components/hero-section'
import CTASection from '../components/cta-section'
import ContactFormSection from '../components/contact-form-section'
import TestimonialsSection from '../components/testimonials-section'
import styled from 'styled-components'
import {device} from '../../../shared-ui/theme'

const NotFoundPage = ({location, data, props }) => {  

    const ErrorWrap = styled.div `
    padding-bottom: 120px;
    padding-top: 120px;
    height: 100vh;
    text-align: center;
    @media ${device.medium}{
        padding-bottom: 100px;
        padding-top: 100px;
    }
    @media ${device.small}{
        padding-bottom: 80px;
        padding-top: 80px;
    }
    h1{
        font-size: 250px;
        font-weight: 900;
        letter-spacing: 10px;
        line-height: 1.1;
        margin-bottom: 30px;
        padding: 0;
        color: ${props => props.theme.colors.themeColor};
        @media ${device.large}{
            font-size: 200px;
        }
        @media ${device.medium}{
            font-size: 150px;
            letter-spacing: 8px;
        }
        @media ${device.small}{
            font-size: 100px;
            letter-spacing: 5px;
        }
    }
    h2{
        font-size: 25px;
        margin-bottom: 31px;
    }
    p{
        margin-bottom: 36px;
    }
`;

  return (    
    <CustomLayout location={location}>           
      <ErrorWrap>
            <Container>
                <Row>
                    <Col xl={7} lg={8} ml="auto" mr="auto">
                        <h1>404</h1>
                        <h2>OPPS! PAGE NOT BE FOUND</h2>
                        <p>Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarity unavailable.</p>
                        <Button to="/" hover="false">Back to home page</Button>
                    </Col>
                </Row>
            </Container>
        </ErrorWrap>
    </CustomLayout>)
}
 
export default NotFoundPage